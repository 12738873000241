// Place any custom Javascript or jQuery in this file.
// If code needs to wait until the DOM is ready, place within the jQuery function below.
(function ($) {
	// init controller
	var controller = new ScrollMagic.Controller({
		globalSceneOptions: {
			trigger: "onCenter",
			offset: 100,
		},
	});

	// create scenes. Since we're using a single class to trigger CSS animations, let's loop through quick.

	var scenes = [
		"#scene-stat-1",
		"#scene-stat-2",
		"#scene-stat-3",
		"#scene-segway",
		"#image_reveals",
		"#scene-news",
		"#scene-trailer",
		"#scene-cta",
	];

	scenes.forEach(function (value) {
		var scene = new ScrollMagic.Scene({
			triggerElement: value,
			reverse: false,
		}).setClassToggle(value, "trigger-anim");
		// .addIndicators();

		scene
			.on("add", function (event) {
				$(value).addClass("stage-anim");
			})
			.addTo(controller);
	});

	// UPDATE 2020-09-10: Used to add animations to career sections.
	// Grab any elements with .career-exploration.
	var $careerSections = document.getElementsByClassName("career-exploration");

	// Convert to an Array.
	$careerSections = Array.prototype.slice.call($careerSections);

	// Loop through array and hook into ScrollMagic to generate classes on scroll.
	// If using an Array, then no calls inside the function need to be changed.
	// $careerSections.forEach(function (value) {
	// 	var scene = new ScrollMagic.Scene({
	// 		triggerElement: value,
	// 		reverse: false,
	// 	}).setClassToggle(value, "trigger-anim");
	// 	// .addIndicators();

	// 	scene
	// 		.on("add", function (event) {
	// 			$(value).addClass("stage-anim");
	// 		})
	// 		.addTo(controller);
	// });
})(jQuery);
